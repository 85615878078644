// src/components/FeaturedSection.js


import React from 'react';
import { useNavigate } from 'react-router-dom';
import './FeaturedSection.css'; // Add styles for the section
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ModelContent from './ModelContent'; // Import the Model component

const FeaturedSectionContent = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleButtonClick = () => {
    navigate('/projects/content'); // Navigate to the content Projects page
  };

  return (
    <div className="featured-section-content">
      <div className="model-container">
        <Canvas
          className="featured-canvas"
          style={{ height: '300px', width: '300px', margin: '0 auto' }} // Adjust size and center it
          camera={{ position: [0, 1, 0.8], fov: 50 }}
        >
          <ambientLight intensity={0.5} />
          <directionalLight position={[0, 0, 5]} intensity={1} />
          <ModelContent />
          <OrbitControls enableZoom={false} />
        </Canvas>
      </div>
      <div className="featured-content">
        <h1>Explore Our Created Content & Publishing Services</h1>
        <h3>Copywriting & Report Generation</h3>
        <p>Our team excels in crafting compelling copy and generating insightful reports that effectively communicate your message and captivate your audience. 
          Whether it's creating engaging marketing materials or detailed analytical reports, we ensure clarity, precision, and impact in every piece of writing.</p>
        <h3>Publishing</h3>
        <p>
        SkyCorals provides end-to-end publishing services tailored for Amazon KDP and IngramSpark, guiding authors through every step of the journey from manuscript to market. 
        Our offerings include expert book editing, layout and cover design, formatting for Kindle and print, ISBN acquisition, and translation services, ensuring a polished, professional result. 
        </p>
        <h3>Concept & Asset Design</h3>
        <p>We transform ideas into tangible assets with our innovative concept and asset design services. 
          From developing original concepts to creating visual assets that resonate with your target audience, we provide the creative vision and technical expertise needed to bring your projects to life.</p>
        <h3>Content Generation</h3>
        <p>Our content generation services are designed to produce high-quality, relevant, and engaging content that aligns with your brand's voice and goals. From blog posts and articles to social media content and more, we create content that not only informs but also inspires and connects with your audience.</p>
        <button onClick={handleButtonClick}>Visit Our Created Content</button>
      </div>
    </div>
  );
};

export default FeaturedSectionContent;
