// src/components/FeaturedSection.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './FeaturedSection.css'; // Add styles for the section
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ModelAI from './ModelAI'; // Import the Model component

const FeaturedSectionAI = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleButtonClick = () => {
    navigate('/projects/ai'); // Navigate to the AI Projects page
  };

  return (
    <div className="featured-section-AI">
      <div className="model-container">
        <Canvas
          className="featured-canvas"
          style={{ height: '300px', width: '300px', margin: '0 auto' }} // Adjust size and center it
          camera={{ position: [-2, -1, 6], fov: 50 }}
        >
          <ambientLight intensity={0.5} color="#0000ff"/>
          <directionalLight position={[0, 0, 5]} intensity={1} color="#0000ff"/>
          <ModelAI />
          <OrbitControls enableZoom={false} enableRotate={false}/>
        </Canvas>
      </div>
      <div className="featured-content">
        <h1>Explore Our AI & ML Projects</h1>
        <h3>AI-Powered Chatbots</h3>
        <p>
        Our Retrieval-Augmented Generation (RAG) chatbots provide accurate, up-to-date responses by retrieving information from your custom knowledge base. Whether for customer support, research assistance, or business automation, our RAG-powered solutions ensure reliable, domain-specific interactions with users.
        </p>
        <h3>Large Language Models (LLM)</h3>
        <p>
          Harness the capabilities of Large Language Models to enhance your business operations. 
          From generating human-like text to automating complex language tasks, our LLM solutions provide powerful
          tools for communication, content creation, and customer interaction.
        </p>
        <h3>Natural Language Processing (NLP)</h3>
        <p>
          Our Natural Language Processing solutions transform the way businesses interact with data and customers.
          By enabling machines to understand and respond to human language, 
          NLP enhances customer service, automates processes, and unlocks insights from unstructured data.
        </p>
        <h3>Business Intelligence & Advanced Analytics</h3>
        <p>
          Transform raw data into actionable insights with our business intelligence and advanced analytics solutions.
          We combine cutting-edge AI techniques with robust data analysis to provide comprehensive insights that
          drive strategic decisions and optimize business performance.
        </p>
        <button onClick={handleButtonClick}><span>Visit Our AI & ML Projects</span></button>
      </div>
    </div>
  );
};

export default FeaturedSectionAI;
