import React, { useEffect } from 'react';
import './Evolution.css';

const Evolution = () => {
  useEffect(() => {
    const image = document.querySelector('.evolution-image');

    const handleMouseMove = (e) => {
      const { clientX: mouseX, clientY: mouseY } = e;
      const { offsetLeft, offsetTop, clientWidth, clientHeight } = image;

      const x = mouseX - offsetLeft;
      const y = mouseY - offsetTop;

      const percentX = (x / clientWidth) * 100;
      const percentY = (y / clientHeight) * 100;

      image.style.transform = `perspective(500px) rotateX(${(percentY - 50) * 0.2}deg) rotateY(${(percentX - 50) * 0.2}deg) scale(1.1)`;
      image.style.transition = 'transform 0.1s ease-out';
    };

    const handleMouseLeave = () => {
      image.style.transform = 'none';
      image.style.transition = 'transform 0.5s ease';
    };

    image.addEventListener('mousemove', handleMouseMove);
    image.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      image.removeEventListener('mousemove', handleMouseMove);
      image.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div className="evolution">
      <div className="evolution-title">Evolution of Work Right Before Your Eyes</div>
      <img src="/assets/Image-Evolution.png" alt="Evolution" className="evolution-image" />
    </div>
  );
};

export default Evolution;
