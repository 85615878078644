import React from 'react';
import './Marquee.css';

const Marquee = () => {
  const items = [
    { text: "AI & ML PIPELINES", logo: "/assets/logos/AIML.png" },
    { text: "AI-DRIVEN CHATBOTS", logo: "/assets/logos/Chatbot.png" },
    { text: "Data-Insights-as-a-Service [DIAAS]", logo: "/assets/logos/DIaaS.png" },
    { text: "FULLSTACK CLOUD SOLUTIONS", logo: "/assets/logos/Cloud.png" },
    { text: "CUSTOM AFFORDABLE LLMs", logo: "/assets/logos/Custom.png" },
    { text: "AI & ML PIPELINES", logo: "/assets/logos/AIML.png" },
    { text: "AI-DRIVEN CHATBOTS", logo: "/assets/logos/Chatbot.png" },
    { text: "Data-Insights-as-a-Service [DIAAS]", logo: "/assets/logos/DIaaS.png" },
    { text: "FULLSTACK CLOUD SOLUTIONS", logo: "/assets/logos/Cloud.png" },
    { text: "CUSTOM AFFORDABLE LLMs", logo: "/assets/logos/Custom.png" },
    { text: "AI & ML PIPELINES", logo: "/assets/logos/AIML.png" },
    { text: "AI-DRIVEN CHATBOTS", logo: "/assets/logos/Chatbot.png" },
    { text: "Data-Insights-as-a-Service [DIAAS]", logo: "/assets/logos/DIaaS.png" },
    { text: "FULLSTACK CLOUD SOLUTIONS", logo: "/assets/logos/Cloud.png" },
    { text: "CUSTOM AFFORDABLE LLMs", logo: "/assets/logos/Custom.png" },
  ];

  return (
    <div className="marquee">
      <div className="marquee-content">
        {items.concat(items).map((item, index) => (
          <div key={index} className="marquee-item">
            <img src={item.logo} alt={item.text} className="marquee-logo" />
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
