import { useEffect } from "react";

const DialogflowChatbot = () => {
  useEffect(() => {
    if (!document.querySelector("script[src*='df-messenger']")) {
      const script = document.createElement("script");
      script.src = "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
  return (
    <df-messenger
      project-id="skycorals"
      agent-id="29d30737-6227-4b84-9ce8-4b3edabf4882"
      language-code="en"
      max-query-length="-1"
      style={{
        zIndex: "1001",
        position: "fixed",
        bottom: "16px",
        right: "16px",
        "--df-messenger-font-color": "#000",
        "--df-messenger-font-family": "League Spartan",
        "--df-messenger-chat-background": "#f3f6fc",
        "--df-messenger-message-user-background": "#d3e3fd",
        "--df-messenger-message-bot-background": "#fff",
      }}
    >
      <df-messenger-chat-bubble chat-title="SkyCorals CB"></df-messenger-chat-bubble>
    </df-messenger>
  );
};

export default DialogflowChatbot;
